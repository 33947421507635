<template>
  <div class="mainTem">
    <!-- URL -->
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'UserManage' }">{{$t('i18nn_55793d80c64c2dc7')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('i18nn_fcd37bf760bc15c3')}}</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div class="tableConTop">
      <el-row>
        <el-col :span="16" class="tableConTopLeft">
					<h3>
						<span>{{$t('i18nn_fcd37bf760bc15c3')}}</span>
						<span>
							[{{Menukey}}]
						</span>
					</h3>
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right"> -->
            <!-- <el-breadcrumb-item> -->
							
						<!-- </el-breadcrumb-item> -->
          <!-- </el-breadcrumb> -->
        </el-col>
        <el-col :span="8" class="tableConTopRig">
          <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
          <!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button> -->
        </el-col>
      </el-row>
    </div>
    <!-- 主界面 -->
    <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
      <!-- 按钮 -->
      <!-- <div class="tableConHeader">
        <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button>
      </div> -->
      <!-- 表格 -->
      <div class="tableConTable">


        <el-table :height="$store.state.tableMaxHeight2" :data="tableData" stripe border size="small" style="width: 100%"
            
            row-key="menuId"
           
            :tree-props="{children: 'menuDtoList', hasChildren: 'hasChildren'}">
            <!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" width="50" align="center">
            </el-table-column> -->

            <el-table-column label="菜单ID" prop="menuId" min-width="230"></el-table-column>
						
						<el-table-column label="ICON" prop="iconcls" min-width="100">
							<template slot-scope="scope">
								<div>
									<i :class="scope.row.iconcls"></i>{{scope.row.iconcls}}
								</div>
							</template>
						</el-table-column>
						
            <el-table-column :label="$t('i18nn_fe054fb92fbd6690')" min-width="300">
              <template slot-scope="scope">
								<div>
									<span v-if="scope.row.leaf == 0 || scope.row.leaf == 1">
									  <label class="el-icon-menu">
											&nbsp;<span>{{scope.row.menuName}}</span>
										</label>
									</span>
									<span v-else>
									  &nbsp;&nbsp;&nbsp;&nbsp;
									  <label class="el-icon-tickets">
											&nbsp;<span>{{scope.row.menuName}}</span>
										</label>
									</span>
									<el-tag v-if="scope.row.leaf == 1">{{$t('i18nn_f3e476303b47045b')}}</el-tag>
									<el-tag type="success" v-else-if="scope.row.leaf == 2">{{$t('i18nn_8e5aa3f56700e4b0')}}</el-tag>
									<el-tag type="warning" v-else-if="scope.row.leaf == 3">{{$t('i18nn_f49f0068c43d8f20')}}</el-tag>
									<el-tag type="info" v-else>{{scope.row.leaf}}</el-tag>
								</div>
                
              </template>
            </el-table-column>
						
						<el-table-column :label="$t('i18nn_af76f74cc6174a6d')" prop="menuNameEn"></el-table-column>

            <el-table-column label="URL" prop="request" min-width="300"></el-table-column>

            <el-table-column :label="$t('i18nn_a5c7073db104f92e')" prop="isShow">
              <template slot-scope="scope">
                <el-tag v-if="'0'===scope.row.isShow">{{$t('i18nn_d79e2ed6a2fc66fd')}}</el-tag>
                <el-tag v-else-if="'1'===scope.row.isShow" type="info">{{$t('i18nn_b978a8259ecfca8f')}}</el-tag>
                <el-tag v-else type="info">{{scope.row.isShow}}</el-tag>
              </template>
            </el-table-column>

            <el-table-column :label="$t('i18nn_dbcffdf31e86d2d8')" prop="sortNo">
            </el-table-column>

            <el-table-column :label="$t('93f5ca01b006206c')" min-width="200px" fixed="right">
              <template slot-scope="scope">
                <el-button type="warning" size="mini" icon="el-icon-edit" @click="openDioalog(scope.row)">{{$t('6267f3aedf895209')}}</el-button>
                <el-button type="danger" size="mini" icon="el-icon-minus" @click="delPageAction(scope.row)">{{$t('e33c9b93c36fd250')}}</el-button>
              </template>
            </el-table-column>
        </el-table>
        <!-- <el-table :height="$store.state.tableMaxHeight" :data="tableData" stripe border style="width: 100%">

          <el-table-column type="index" :index="$Utils.tableIndex(pagination)" width="50"  fixed="left" align="center">
          </el-table-column>
          <el-table-column label="菜单ID" prop="menuId" width="200"></el-table-column>
          <el-table-column :label="$t('i18nn_fe054fb92fbd6690')" width="300">
            <template slot-scope="scope">
              <div v-if="scope.row.leaf == 0 || scope.row.leaf == 1">
                <label class="el-icon-menu">&nbsp;&nbsp;{{scope.row.menuName}}</label>
              </div>
              <div v-else>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label class="el-icon-tickets">&nbsp;&nbsp;{{scope.row.menuName}}</label>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="URL" prop="request" width="400"></el-table-column>

          <el-table-column :label="$t('i18nn_a5c7073db104f92e')" prop="isShow">
            <template slot-scope="scope">
              <el-tag v-if="'0'===scope.row.isShow">{{$t('i18nn_d79e2ed6a2fc66fd')}}</el-tag>
              <el-tag v-else-if="'1'===scope.row.isShow" type="info">{{$t('i18nn_b978a8259ecfca8f')}}</el-tag>
              <el-tag v-else type="info">{{scope.row.isShow}}</el-tag>
            </template>
          </el-table-column>

          <el-table-column :label="$t('i18nn_dbcffdf31e86d2d8')" prop="sortNo">

          </el-table-column>

          <el-table-column :label="$t('93f5ca01b006206c')" width="200px" fixed="right">
            <template slot-scope="scope">
              <el-button type="warning" size="mini" icon="el-icon-edit" @click="openDioalog(scope.row)">{{$t('6267f3aedf895209')}}</el-button>
              <el-button type="danger" size="mini" icon="el-icon-minus" @click="delPageAction(scope.row)">{{$t('e33c9b93c36fd250')}}</el-button>
            </template>
          </el-table-column>
        </el-table> -->
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :title="$t('i18nn_ba14fba14998cbd6')" :close-on-click-modal="false"  :visible.sync="dialogFormVisible">
      <el-form ref="form" :rules="formRules" :model="form" label-width="100px" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
        <el-form-item label="上级菜单：" required>
          <!-- <el-select filterable clearable v-model="form.parentId" :placeholder="$t('i18nn_f241d2c8737ad7ba')" @change="selChange($event)">
            <el-option v-for="item in dialogTableDate" :key="item.menuId" :label="item.menuName" :value="item.menuId">
              <div v-if="item.leaf == null || item.leaf == 0">
                <i class="el-icon-s-home"></i>{{item.menuName}}
              </div>
              <div v-else-if="item.leaf == 1" style="padding-left: 10px;">
                <label class="el-icon-menu">{{item.menuName}}</label>
              </div>
              <div v-else style="padding-left: 20px;">
                <label class="el-icon-tickets">{{item.menuName}}</label>
              </div>
            </el-option>
          </el-select> -->
					
					<el-cascader
					    v-model="form.parentIdArr"
					    :options="dialogTableDate"
							filterable
							clearable
					    :props="{ 
								expandTrigger: 'hover',
								checkStrictly: true,
								value:'menuId',
								label:'menuName',
								children:'menuDtoList'
							}"
					    @change="handleChange"></el-cascader>
							
        </el-form-item>
				
        <el-form-item label="菜单名称：" prop="menuName">
          <el-input type="text" clearable v-model="form.menuName" :placeholder="$t('i18nn_32fc9710fbb0291b')"></el-input>
        </el-form-item>
				
				<el-form-item label="英文名称：" prop="menuNameEn">
				  <el-input type="text" clearable v-model="form.menuNameEn" :placeholder="$t('i18nn_b7004d634b60c827')"></el-input>
				</el-form-item>
				
        <el-form-item label="URL地址：" prop="request">
					<!-- <el-alert title="顶级菜单URL必须含'WarehouseCenter$t('i18nn_973a0a2f334d7703')
						type="warning">
					</el-alert> -->
          <el-input type="text" clearable v-model="form.request" placeholder="请输入URL地址"></el-input>
        </el-form-item>
        <el-form-item label="是否隐藏：">
          <el-switch
            v-model="form.isShow"
						active-value="0"
						inactive-value="1"
            :active-text="$t('i18nn_d79e2ed6a2fc66fd')"
            :inactive-text="$t('i18nn_b978a8259ecfca8f')">
          </el-switch>
        </el-form-item>
        <el-form-item label="排序：" prop="sortNo">
          <!-- <el-input type="text" clearable v-model="form.sortNo" :placeholder="$t('i18nn_61e631acdbe6900b')"></el-input> -->
					<el-input-number v-model="form.sortNo" controls-position="right"></el-input-number>
        </el-form-item>
				<el-form-item label="ICON：" prop="iconcls">
				  <el-input type="text" clearable v-model="form.iconcls" placeholder="请输入icon"></el-input>
				</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('4e9fc68608c60999')}}</el-button>
        <el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('4ce9979bfb6576d9')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
  	openTime: {
  
  	},
  	Menukey: {
			default: function() {
			  return 'WarehouseCenter';
			},
			type: String
		}
  },
	
  data() {
    return {
      // 要展开的行，数值的元素是row的key值
      // expands: [],

      // 表单信息
      tableData: [],
      loading_load: false,
      // 弹窗信息
      dialogTableDate: [],
      dialogFormVisible: false,
      loading: false,
      form: {
        "menuId": null,
				"iconcls":"",
        "menuName": "", //菜单名称
				"menuNameEn":"",
        "request": "", //URL
        "parentId": "0", // 上级菜单
				"parentIdArr":[],//上级所有级菜单
        "parentName": "", // 上级菜单
        "isShow":'0',
        "sortNo":"1",
        "leaf": 0,
        // "parentLeaf": 0
      },
      formRules: {
        parentId: [
          { required: true, message: this.$t('i18nn_f241d2c8737ad7ba'), trigger: 'change' },
        ],
				parentIdArr: [
          { required: true, message: this.$t('i18nn_f241d2c8737ad7ba'), trigger: 'change' },
        ],
        menuName: [
          { required: true, message: this.$t('i18nn_32fc9710fbb0291b'), trigger: 'blur' },
        ],
				menuNameEn: [
          { required: true, message: this.$t('i18nn_b7004d634b60c827'), trigger: 'blur' },
        ],
        request: [
          { required: true, message: '请输入URL', trigger: 'blur' },
        ],
        isShow: [
          { required: true, message: this.$t('i18nn_85d564c86ace60d8'), trigger: 'change' },
        ],
        sortNo: [
          // { required: true, message: this.$t('i18nn_61e631acdbe6900b'), trigger: 'blur' },
          { pattern: this.$Validate.RegNumber,message: this.$t('057d4e3f1ee2d9bc')},
          { pattern: this.$Validate.RegInteger,message: this.$t('i18nn_5d2e2702224d280d')}
        ],
        // ],
        // parentName: [
        //   { required: true, message: this.$t('i18nn_f241d2c8737ad7ba'), trigger: 'change' },
        // ],
      }
    }
  },
	watch: {
		openTime: function(newVal, oldVal) {
			console.log('openTime');
			// this.dialogFile = true;
			this.initData();
		}
	},
	activated() {
		this.initData();
	},
  //创建时
  created() {
    // this.initData();
  },
  //编译挂载前
  mounted() {},
  methods: {
    //表格点击行展开，在<table>里，我们已经设置row的key值设置为每行数据id：row-key="id"
    // rowClick(row, event, column) {
    //   // Array.prototype.remove = function(val) {
    //   //   let index = this.indexOf(val);
    //   //   if (index > -1) {
    //   //     this.splice(index, 1);
    //   //   }
    //   // };
    //   console.log(row);
    //   console.log(this.expands);
    //   if (this.expands.indexOf(row.menuId) < 0) {
    //     this.expands.push(row.menuId);
    //   } else {
    //     // this.expands.remove(row.id);
    //     let index = this.expands.indexOf(row.menuId);
    //     if (index > -1) {
    //       this.expands.splice(index, 1);
    //     }
    //   }
    // },
    // 初始化数据
    initData() {
      this.getPageData();
    },
    initFormDate() {
      this.form = {
        "menuId": null,
				"iconcls":"",
        "menuName": "", //菜单名称
				"menuNameEn":"",
        "request": "", //URL
        "parentId": "0", // 上级菜单
				"parentIdArr":[],
        "parentName": "", // 上级菜单
        "isShow":'0',//是否隐藏
        "sortNo":"1",//排序
        "leaf": 0,
        // "parentLeaf": 0
      }
    },
    // 查询菜单集合
    getPageData() {
      this.loading_load = true;
      this.$http.post(this.$urlConfig.HyMenuAllPage, null)
        .then(({ data }) => {
          console.log("菜单列表，请求成功");
          console.log(data);
          this.loading_load = false;
          if (200 == data.code && data.rows != null && data.rows.length > 0) {
            // var index = 0;
            // var newMuen = [];
            // for (var i = 0; i < data.rows.length; i++) {
            //   var menu = data.rows[i];
            //   newMuen[index] = menu;
            //   newMuen[index].exists = 0;
            //   newMuen[index].leaf = parseInt(newMuen[index].leaf);

            //   // 循环二级
            //   if (menu.menuDtoList != null && menu.menuDtoList.length > 0) {
            //     for (var j = 0; j < menu.menuDtoList.length; j++) {
            //       newMuen[index].exists = 1;

            //       index++;
            //       var twoMenu = menu.menuDtoList[j];
            //       newMuen[index] = twoMenu;
            //       newMuen[index].leaf = parseInt(newMuen[index].leaf);

            //       // if (twoMenu.menuDtoList != null && twoMenu.menuDtoList.length > 0) {
            //       //    for (var k = 0; k < twoMenu.menuDtoList.length; k++) {
            //       //     index++;
            //       //     newMuen[index] = twoMenu.menuDtoList[k];
            //       //   }
            //       // }
            //     }
            //   }
            //   index++;
            // }
            // this.tableData = newMuen;
            let  tableData = data.rows;
						let WhTableData = tableData.filter(item=>{
							// item.children = item.menuDtoList;
							// if(item.request && item.request.indexOf('WarehouseCenter')>-1){
							// 	return item
							// }
							return item.request && item.request.indexOf(this.Menukey)>-1
						});
            // WhTableData.map(item=>{
            //   item.children = item.menuDtoList;
            // });
            this.tableData = WhTableData;
            // this.$message.success('菜单列表数据处理成功');
            // console.log(newMuen);
          } else {
            this.$message.warning(data.msg?data.msg:this.$t('hytxs0000029'));
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("菜单列表，请求失败");
          this.$message.error('列表数据，请求失败！');

          this.loading_load = false;
        });
    },
    //打开弹窗
    openDioalog(openData) {
      console.log('openData',openData);
      this.dialogTableDate = this.tableData.concat([]);
			this.dialogTableDate.unshift({
        "menuId": "0",
				"iconcls":"",
        "menuName": this.$t('i18nn_4af676d69de62f2b'),
				"menuNameEn":"Top Menu",
        "request": "#",
        "parentId": "0",
				"parentIdArr":[],
        "leaf": 0,
        // "parentLeaf": 0,
        "isShow":'0',//是否隐藏
        "sortNo":"1",//排序
      })
      // this.dialogTableDate[0] = {
      //   "menuId": "0",
      //   "menuName": this.$t('i18nn_4af676d69de62f2b'),
      //   "request": "#",
      //   "parentId": "0",
      //   "leaf": 0,
      //   "parentLeaf": 0,
      //   "isShow":true,//是否隐藏
      //   "sortNo":"1",//排序
      // };
      // if (this.tableData != null) {
      //   for (let i = 1; i <= this.tableData.length; i++) {
      //     this.dialogTableDate[i] = this.tableData[i - 1];
      //   }
      // }
      this.dialogFormVisible = true;
			this.resetForm('form');
      // 新增
      if (null == openData) {
        // this.resetForm('form');
        this.initFormDate();
      }
      // 编辑
      else {
        // this.resetForm('form');

        this.form = Object.assign({}, openData);
				
				let selObjArr = this.findTreeNameByParentId(this.tableData,this.form.parentId);
				console.log('selObjArr',selObjArr);
				// return;
				if(selObjArr && selObjArr.length>1){
					let parentIdArr = selObjArr.map(item=> {
						return item.menuId;
					});
					this.form.parentIdArr = parentIdArr;
				} else {
					this.form.parentIdArr = [this.form.parentId];
				}
				
        // this.form.isShow = '1'==this.form.isShow?false:true;
        // this.selChange(this.form.parentId);
      }
    },
		
		//根据 ParentId 查询树形对象,返回上级
		findTreeNameByParentId(tree,parentId){
			// if('0'==parentId){//顶级菜单
			// 	return [parentId]
			// }
			
			// let lv1 = tree.find(item=>{
			// 	return 
			// })
			
			for (let i = 0, ln = tree.length; i < ln; i++) {
				let lv1 = tree[i];
				if (parentId == lv1.menuId) {
					// selObj = lv1;
					// break;
					return [lv1];
				}
				if (lv1.menuDtoList) {
					for (let j = 0, lnn = lv1.menuDtoList.length; j < lnn; j++) {
						let lv2 = lv1.menuDtoList[j];
						if (parentId == lv2.menuId) {
							// selObj = lv2;
							// break;
							return [lv1,lv2];
						}
						// if (lv2.menuDtoList) {
						// 	for (let k = 0, lnk = lv2.menuDtoList.length; k < lnk; k++) {
						// 		let lv3 = lv2.menuDtoList[k];
						// 		if (parentId == lv3.menuId) {
						// 			return [lv1,lv2,lv3];
						// 		}
						// 	}
						// }
					}
				}
			}
		},
    //下拉框选择改变
    // selChange(vId) {
    //   if (vId == null) {
    //     return;
    //   }
    //   for (let i = 0; i < this.dialogTableDate.length; i++) {
    //     if (this.dialogTableDate[i].menuId == vId) {
    //       this.form.parentLeaf = this.dialogTableDate[i].leaf;
    //       break;
    //     }
    //   }
    // },
		
		//级联选择
		handleChange(v){
			console.log(v);
			
		},
		
    // 提交信息
    submitForm(formName) {
			if(this.form.parentIdArr.length<=0){
				this.$message.warning(this.$t('i18nn_f241d2c8737ad7ba'));
				return;
			}
			if(this.form.parentIdArr.length>2){
				this.$message.warning(this.$t('i18nn_a9d1938b369aff5b'));
				return;
			}
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = Object.assign({}, this.form);
					// console.log('formData',formData);
					// return;
					formData.parentId = formData.parentIdArr[formData.parentIdArr.length-1];
					if(formData.parentId == '0'){
						formData.leaf = 1;
					} else {
						// formData.leaf = parseInt(formData.parentLeaf) + 1;
						if(formData.parentIdArr.length==1){
							formData.leaf = 2;
						} else if(formData.parentIdArr.length==2){
							formData.leaf = 3;
						}
					}
					//顶层菜单必须含有‘WarehouseCenter’
					if(formData.parentId == '0' && formData.request.indexOf(this.Menukey)<=-1){
						this.$alert(`顶级菜单URL必须含'${this.Menukey}'字符串，便于区分是否是仓库菜单!`, this.$t('cc62f4bf31d661e3'), {
						  type: 'warning',
						  // confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return;
					}

          formData.expand = 0;
          // formData.isShow = formData.isShow?'0':'1';
          formData.id = formData.menuId;

          console.log(formData);
					// return;
          if (formData.menuId == null) {
            this.postData(this.$urlConfig.HyBckMenuAdd, formData);
          } else {
            this.postData(this.$urlConfig.HyBckMenuModify, formData);
          }
        } else {
          console.log('error submit!!');
          this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870'),
          });
          return false;
        }
      });
    },
    //提交信息
    postData(url, formData) {
      this.loading = true;
      this.$http.post(url, formData)
        .then(({ data }) => {
          console.log(this.$t('i18nn_bc868e024b80d2e3'));
          console.log(data);
          this.loading = false;
          if (200 == data.code) {
            this.dialogFormVisible = false;
            this.getPageData();
            this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
              type: 'success',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
          } else {
            if (!data.msg) {
              data.msg = "提交失败,请重试";
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
          }
          // this.getPageData();
        })
        .catch((error) => {
          console.log(error);
          console.log(this.$t('i18nn_a7d2e953195a5588'));
          this.loading = false;
          this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870'),
          });
        });
    },
    // 重置输入框
    resetForm(formName) {
      console.log(formName);
      this[formName] = {};
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
        // this.form.remark = ""; //  备注//非必填
      } else {
        console.log('this.$refs[formName]', this.$refs[formName]);
      }
    }, //删除操作
    delPageAction(dataParm) {
      if (dataParm.menuDtoList != null && dataParm.menuDtoList.length > 0) {
        this.$alert('请先删除下级菜单！', this.$t('cc62f4bf31d661e3'), {
          type: 'warning',
          confirmButtonText: this.$t('204ffab8a6e01870'),
        });
        return;
      }

      this.$confirm(this.$t('i18nn_18a47a2171c01d1c') + dataParm.menuName + '吗?', this.$t('daaaeb1b7b22b126'), {
        confirmButtonText: this.$t('204ffab8a6e01870'),
        cancelButtonText: this.$t('4b3340f7872b411f'),
        type: 'warning'
      }).then(() => {
        this.delPageData(dataParm);
      }).catch(() => {});
    },
    //删除特定数据
    delPageData(dataParm) {
      console.log(dataParm);
      this.loading_load = true;
      this.$http.post(this.$urlConfig.HyBckMenuDel, { id: dataParm.menuId })
        .then(({ data }) => {
          this.loading_load = false;
          if (200 == data.code) {
            this.$alert('恭喜,删除成功！', this.$t('cc62f4bf31d661e3'), {
              type: 'success',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
            this.getPageData();
          } else {
            if (!data.msg) {
              data.msg = "删除失败,请重试";
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
          }
          // this.getPageData();
        })
        .catch((error) => {
          console.log(error);
          this.loading_load = false;
          this.$alert('删除失败,请重试！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870'),
          });
        });
    },
  }
}

</script>
<!-- Add "scoped" attribute to limit leaf to this component only -->
<style scoped>
/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/

</style>

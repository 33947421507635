<template>
	<div>


		<el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
			<el-tab-pane :label="$t('i18nn_84b54464d1e28163')" name="first">
				<MenuConfComp :Menukey="'WarehouseCenter'" :openTime="firstOpenTime"></MenuConfComp>
			</el-tab-pane>
			<el-tab-pane label="PDA菜单" name="second">
				<MenuConfComp :Menukey="'PdaCenter'" :openTime="secondOpenTime"></MenuConfComp>
			</el-tab-pane>
		</el-tabs>

	</div>
</template>
<script>
	import MenuConfComp from '@/components/WarehouseCenter2/PermissionConfig/MenuConfComp.vue';

	export default {
		components: {
			MenuConfComp,
		},
		data() {
			return {
				activeName: 'first',
				firstOpenTime: '',
				secondOpenTime: ''
			}
		},

		activated() {
			// this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
				if ('0' == tab.index) {
					this.firstOpenTime = new Date().getTime();
				} else if ('1' == tab.index) {
					this.secondOpenTime = new Date().getTime();
				}
			}
		}
	}
</script>
<!-- Add "scoped" attribute to limit leaf to this component only -->
<style scoped>
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>